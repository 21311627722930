:root {
  --text-light: #000000;
  --text-dark: #ffffff;
  --background-light: #ffffff;
  --background-dark: #121212;
}

body {
  background-color: var(--background-light);
  color: var(--text-light);
}

[data-theme="dark"] {
  --background-light: #121212;
  --background-dark: #121212;
  --text-light: #f2f2f2;
  --text-dark: #000000;
}

.journey-section {
  background-color: var(--background-light);
  color: var(--text-light);
}

.journey-section h4, .journey-section p {
  color: inherit;
}

.text-mobile-center {
  text-align: center;
}

body, .journey-section {
  transition: background-color 0.3s, color 0.3s;
}



@media (max-width: 767.98px) {
    .text-mobile-center {
        text-align: center;
    }
}

@media (min-width: 768px) {
    .text-mobile-center {
        text-align: left;
    }
}
