.profile-summary {
  background: url('../images/Richard\ S\ Nasong\'o\ Issuing\ an\ Award\ to\ UoN\ for\ 1st\ Runners\ Up\ Performance\ in\ Mainstreaming\ Gender\ in\ Academia.jpg') no-repeat center center;
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
  position: relative;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: backgroundGrow 10s ease-in-out forwards;
}

.text-justify {
  text-align: justify;
}

.profile-summary::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.profile-summary .container {
  position: relative;
  z-index: 2;
}

@keyframes backgroundGrow {
  0% {
    background-size: 100%;
  }
  100% {
    background-size: 120%;
  }
}

@media (max-width: 768px) {
  .profile-summary {
    background-size: cover;
    padding-top: 6rem;
    padding-bottom: 2rem;
  }

  .text-justify {
    text-align: center;
  }
}