.carousel-item {
  min-height: 100vh;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
}

.carousel-caption h1 {
  font-size: 3rem;
}

.carousel-caption p {
  font-size: 1.25rem;
}

@media (max-width: 768px) {
  .carousel-caption h1 {
    font-size: 2rem;
  }

  .carousel-caption {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    color: white;
  }

  .carousel-caption p {
    font-size: 1rem;
  }
}

@media (max-width: 400px) {
  .carousel-item {
    min-height: 120vh;
  }
}

@media (max-width: 380px) {
  .carousel-item {
    min-height: 140vh;
  }
}

@media (max-width: 350px) {
  .carousel-item {
    min-height: 120vh;
  }
}

