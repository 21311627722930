/* Define light and dark theme variables */
:root {
  --carousel-caption-bg-light: rgba(0, 0, 0, 0.5); /* Light mode caption background */
  --carousel-caption-bg-dark: rgba(255, 255, 255, 0.5); /* Dark mode caption background */
  --carousel-caption-text-light: white; /* Light mode caption text color */
  --carousel-caption-text-dark: black; /* Dark mode caption text color */
}

/* Specific Service Styles */
.specificService {
    padding-top: 6rem;
}

/* Responsive iframe */
.responsive-iframe {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.responsive-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.specificService .carousel-item {
    min-height: 400px;
}

.specificService .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

/* Carousel Caption */
.specificService .carousel-caption {
    bottom: 20px;
}

/* Carousel Caption Text */
.specificService .carousel-caption p {
    font-size: 1.25rem;
    font-weight: normal;
}

/* Dark and Light Mode Caption Styles */
.specificService .carousel-caption-bottom {
    background-color: var(--carousel-caption-bg-light); /* Default light mode background */
    color: var(--carousel-caption-text-light); /* Default text color for light mode */
    padding: 10px;
    border-radius: 10px;
    margin-top: 15px;
    text-align: center;
}

/* Dark Mode Adjustments */
[data-theme="dark"] .specificService .carousel-caption-bottom {
    background-color: var(--carousel-caption-bg-dark); /* Dark mode background */
    color: var(--carousel-caption-text-dark); /* Dark mode text color */
}


/* Media Query for Small Screens */
@media (max-width: 767px) {
    .specificService .carousel-item {
        min-height: 30vh;
    }
}
