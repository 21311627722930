/* Define light and dark theme variables */
:root {
  --bg-color: #ffffff;
  --text-color: #000000;
  --navbar-bg: #f8f9fa;
  --navbar-text: #000000;
}

[data-theme="dark"] {
  --bg-color: #121212;
  --text-color: #ffffff;
}

/* Cookie Consent Banner Styles */
.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--bg-color); /* Use theme-based background color */
  color: var(--text-color); /* Use theme-based text color */
  padding: 15px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.cookie-consent-banner p {
  margin: 0 0 10px;
  text-align: center;
}

.cookie-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.cookie-buttons button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.accept-button {
  background-color: #007bff;
  color: #f2f2f2;
}

.reject-button {
  background-color: #dc3545;
  color: #f2f2f2;
}

@media (max-width: 768px) {
  .cookie-buttons {
    flex-direction: column;
  }

  .cookie-buttons button {
    width: 100%;
  }
}
