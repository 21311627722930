/* Define light and dark theme variables */
:root {
  --loading-bg-color-light: #f2f2f2;
  --loading-bg-color-dark: #121212;
  --circle-color-light: green;
  --circle-color-dark: #4CAF50; /* Different color for dark mode */
}

/* Loading Container Styles */
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--loading-bg-color-light); /* Default to light mode background */
  z-index: 9999;
}

/* Dark mode background color */
[data-theme="dark"] .loading-container {
  background-color: var(--loading-bg-color-dark); /* Dark mode background */
}

.loading-logo {
  width: 100px;
  margin-bottom: 20px;
}

.loading-circles {
  display: flex;
  gap: 10px;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: var(--circle-color-light); /* Default circle color for light mode */
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}

/* Dark mode circle color */
[data-theme="dark"] .circle {
  background-color: var(--circle-color-dark); /* Dark mode circle color */
}

.circle:nth-child(2) {
  animation-delay: 0.2s;
}

.circle:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}
