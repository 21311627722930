/* Define light and dark theme variables */
:root {
  --bg-color: #ffffff;
  --text-color: #000000;
  --navbar-bg: #f8f9fa;
  --navbar-text: #000000;
}

[data-theme="dark"] {
  --bg-color: #121212;
  --text-color: #ffffff;
}

.first-call-to-action {
  background-image: url('../images/Senior Staff Members represented GDDC at the County Peer Review Mechanism Steering Committee and Stakeholders workshop at Sewela.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 60vh;
}

.first-call-to-action::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.first-call-to-action .container {
  z-index: 2;
}
