@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

.nav-link {
  font-size: 1.5 rem;
}

.navbar-toggler {
  border: none;
  outline: none;
}

.blur-background {
  backdrop-filter: blur(10px);
  transition: backdrop-filter 0.3s ease;
}

.nav-link {
  position: relative;
}

.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: red;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: bottom right;
}

.nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navbar .nav-link {
  color: green;
  transition: color 0.3s ease;
}

.navbar-nav .nav-link.active {
  color: red !important;
}

.navbar .nav-link:hover {
  color: red !important;
}
